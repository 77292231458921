.responsive-text {
  line-height: 1.6;
  margin: 20px;
}

/* Texte pour écrans moyens (tablettes) */
@media (max-width: 768px) {
  .responsive-text {
    font-size: 1.2rem; /* Réduit légèrement la taille de la police */
    line-height: 1.5;
    margin: 15px;
  }
}

iframe {
  max-width: 100%; /* Limite la largeur à 100% du conteneur parent */
  height: auto; /* Maintient le ratio de la vidéo */
  margin: 0 auto; /* Centre la vidéo */
  display: block; /* Supprime tout espace résiduel inline */
  aspect-ratio: 16 / 9; /* Définit le ratio */
}

/* Texte pour très petits écrans (mobiles) */
@media (max-width: 480px) {
  .responsive-text {
    font-size: clamp(
      0.5rem,
      1vw,
      1rem
    ); /* Taille plus petite pour les mobiles */
    line-height: 1.4;
    margin: 10px auto;
    text-align: left; /* Alignement à gauche pour améliorer la lisibilité */
  }
}
img {
  max-width: 100%;
  height: auto;
}
