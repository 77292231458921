@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Dancing+Script&family=Playfair+Display+SC&display=swap');

body {
  margin: 0 13% 0 13%; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --grey: rgb(155, 155, 155);
  --black: rgb(0, 0, 0);
  --white: rgb(235, 235, 235);
  
  /* desktop */
  --desktopPSize: 1.5rem;
  --desktopH1Size: 6rem;
  --desktopH2Size: 2.5rem;
  --desktopH3Size: 2rem;

  /* tablet */
  --tabletPSize: 1.2rem;
  --tabletH1Size: 2.5rem;
  --tabletH2Size: 2rem;
  --tabletH3Size: 1.5rem;

  /* mobile */
  --mobilePSize: 0.9rem;
  --mobileH1Size: 2rem;
  --mobileH2Size: 1.5rem;
  --mobileH3Size: 1rem;

}

h1, h2, h3 {
  font-family: 'Alfa Slab One', cursive;
  text-transform: uppercase;
}
h1 {
  font-size: var(--desktopH1Size);
  letter-spacing: 5px;
}

h2 {
  font-size: var(--desktopH2Size);
  margin: 5% 9% 1% 0;
  padding: 0 0 0 0;
  display: flex;
  letter-spacing: 2px;
}

h3 {
  font-size: var(--desktopH3Size);
  margin: 5% 9% 1% 0;
  padding: 0 0 0 0;
  display: flex;
  letter-spacing: 2px;
}

p {
  font-size: var(--desktopPSize);
}

.button {
  background-color: white;
  color: black;
  border: 2px solid black;
  border-radius: 50px;
  font-family: 'Playfair Display SC', serif;
  font-size: 20px;
  transition-duration: 0.4s;
  padding: 8px 24px;
  margin: 4px 0 0 10px;
  display: inline-block;
}

button {
  cursor: pointer;
}

.button:hover {
  background-color: black;
  color: white;
}

.translateBtn {
  border: none;
  position: fixed;
  top: 1%;
  right: 88%;
  background-color: transparent;
}

.translateBtn:hover {
  cursor: pointer;
  background-color: transparent;
  color: black;
}

@media screen and (max-width: 1200px) {
  h2 {
      font-size: var(--tabletH2Size);
  }
  h3 {
    font-size: var(--tabletH3Size);
  }
}


@media screen and (max-width: 1007px) {
  h1{
    font-size: var(--tabletH1Size);
  }
  p {
    font-size: var(--tabletPSize);
  }
}

@media screen and (max-width: 700px) {

}

@media screen and (max-width: 600px) {
  .button {
    font-size: 10px;
    padding: 2px 5px;
    margin: 2px 0 0 10px;
  }
  p {
    font-size: var(--mobilePSize);
  }
}

@media screen and (max-width: 500px) {
  h1{
      font-size: var(--mobileH1Size);
  }
  h2 {
    font-size: var(--mobileH2Size);
  }
  h3 {
    font-size: var(--mobileH3Size);
  }
}