.header {
    display: flex;
    margin: 1% 0 4% 0;
    justify-content: space-between;
}

.title {
    display: flex;
    align-items: center;
}

.title a {
    font-size: 30px;
    float: inline-start;    
    display: flex;    
    font-family: 'Dancing Script', cursive;
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: black;
    align-items: center;
}

nav {
    display: flex;
    align-items: center;
}

nav ul {
    display: flex;
    list-style-type: none;
    justify-content: center;
}
    
.links li a {
    text-decoration: none;
    color: black;
    padding: 15px 25px;
    font-size: 17px;
    font-family: 'Playfair Display SC', serif;
}

.links li:first-child {
    margin: 0 0 0 50px;
}

.header .burger {
    display: none;
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    color: inherit;
}

/* tablets */
@media screen and (max-width: 1250px) {
    .header .links {
        flex-direction: column;
        justify-content: center;
        position: fixed;
        align-items: center;
        right: 100vh;
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 2rem;
        background-color: black;
    }

    .header .links li a {
        display: block;
        color: white;
        font-size: 2rem;
    }

    .header .burger {
        display: block;
        position: fixed;
        top: 1rem;
        right: 1.5rem;
    }

    .header .burger:hover {
        cursor: pointer;
    }

    nav {
        display: block;
    }

    .links li:first-child {
        margin: 0;
    }

    /* hidden menu start */
    .header .hideNav .burger .burger_line,
    .header .hideNav .burger .burger_line::before,
    .header .hideNav .burger .burger_line::after {
        display: block;
        width: 40px;
        height: 3px;
        position: relative;
        border-radius: 3px;
        background-color: black;
        transition: all .5s ease-out;
    }

    .header .hideNav .burger .burger_line::before,
    .header .hideNav .burger .burger_line::after {
        content: '';
        position: absolute;
    }

    .header .hideNav .burger .burger_line::before {
        transform: translateY(-12px);
    }

    .header .hideNav .burger .burger_line::after {
        transform: translateY(12px);
    }

    .header .hideNav .links {
        right: 0;
        width: 100vw;
        transform: translate(200vh);
        transition: all .6s ease-in;
    }
    /* hidden menu end */
    
    /* show menu start */
    .header .showNav .burger .burger_line,
    .header .showNav .burger .burger_line::before,
    .header .showNav .burger .burger_line::after {
        display: block;
        width: 40px;
        height: 3px;
        position: relative;
        border-radius: 3px;
        background-color: white;
        transition: all .5s ease-in;
    }

    .header .showNav .burger .burger_line::before,
    .header .showNav .burger .burger_line::after {
        content: '';
        position: absolute;
        left: 0;
    }

    .header .showNav .burger .burger_line::before {
        transform: translateY(-12px);
    }

    .header .showNav .burger .burger_line::after {
        transform: translateY(12px);
    }

    .header .showNav .links {
        right: 0;
        width: 100vw;
        transform: translate(0);
        transition: all .6s ease-in;
    }

    /* Cross start */
    .header .showNav .burger .burger_line {
        width: 0;
        background: transparent;
    }

    .header .showNav .burger .burger_line::before {
        transform: rotate(-45deg);
    }

    .header .showNav .burger .burger_line::after {
        transform: rotate(45deg);
    }
    /* Cross end */
    /* show menu end */
}

/* mobile */
@media screen and (max-width: 710px) {
    .header .burger {
        margin-left: 30vh;
    }

    .header .links {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 530px) {
    .header .burger {
        margin-left: 20vh;
    }
}

@media screen and (max-width: 445px) {
    .header .burger {
        margin-left: 15vh;
    }
}

@media screen and (max-width: 375px) {
    .header .burger {
        margin-left: 10vh;
    }
}