p {
    height: 50%;
    width: 70%;

}

.contact {
    display: flex;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    border-radius: 10px;
    padding: 20px;
}

@media screen and (max-width: 1007px) {
    .contact {
        flex-direction: column;
    }
}