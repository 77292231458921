input {
    color: black;
    border: 2px solid black;
    font-family: 'Playfair Display SC', serif;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 8px 54px;
    margin: 2px 4px;
}

.mc__form {
    display: inline-block;
}

button {
    background-color: white;
    color: black;
    border: 2px solid black;
    border-radius: 50px;
    font-family: 'Playfair Display SC', serif;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 8px 24px;
    margin: 4px 0 0 0px;
    display: inline-block;
}

.mc__form-container {
    font-family: 'Playfair Display SC', serif;
    font-size: 20px;
    transition-duration: 0.4s;
    padding: 8px 24px;
    margin: 4px 0 0 0px;
    display: inline-block;
}

button:hover {
    background-color: black;
    color: white;
}

@media  screen and (max-width: 500px) {
    .mc__form-container {
        margin: 0;
        padding: 0;
    }

    input {
        margin: 10px 0;
        padding: 0;
    }

    button {
        font-size: 25px;
        margin: 15px 0;
        padding: 0;
    }
}
    