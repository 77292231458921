.preview-container {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: space-between;
}

.preview-container .button {
    visibility: hidden;
    display: none;
}

.projectButton-flex .title h2 {
    margin: 0;
}

.plus {
    margin: 0;
}

.plus p {
    font-size: 50px;
    margin: 0;
}

/* Start hover */
.preview-container-hover {
    justify-content: space-between;
    display: flex;
}

.preview-containt {
    display: flex;
    width: 60%;
    height: 60%;
}

.preview-containt .picture {
    width: 100%;
}

.preview-containt img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

.projectButton-block {
    display: block;
    margin: auto 0;
    width: 35%;
}

.projectButton-block a {
    text-decoration: none;
    margin: 0;
}

.minus {
    visibility: hidden;
    display: none;
}

.seperate {
    margin: 1% 0;
    display: flex;
    height: 1px;
    width: 100%;
    border-radius: 10px;
    background: #000000;  
}

/* End hover */
@media screen and (max-width: 1200px) {
    .preview-containt .title {
        font-size: 19px;
    }
}

@media screen and (max-width: 1070px) {
    .preview-container-hover {
        display: block;
    }
    .preview-containt .picture {
        width: 100%;
    }
    
    .preview-containt img {
        max-width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 600px) {
    .preview-containt .picture {
        width: 100%;
        display: block;    
    }
    
    .preview-containt img {
        max-width: 100%;
        height: auto;
    }

    .preview-containt .title {
        font-size: 20px;
    }
}