.preview-project {
    width: 400px;
    height: 100%;
    border: 2px;
    border-style: solid;
    border-color: #000;
    margin: 10px;
}

.card-link {
    text-decoration: none;
}

.preview-project .picture {
    display: flex;
    justify-content: center;
    width: 400px;
    height: 500px;
}

.preview-project img {
    max-width: 90%;
    height: auto;
    object-fit: cover;
}

.preview-project .title h3 {
    color: #000;
    margin-left: 20px;
}

.preview-project:hover {
    box-shadow: 3px 3px 15px black;
    transition: 0.5s;
}

@media screen and (max-width: 836px) {
    .preview-project {
        width: 300px;
        height: auto;
    }

    .preview-project .title h3 {
        font-size: 18px;
    }

    .preview-project .picture {
        width: 280px;
        height: auto;
        margin: 0 0 10px 10px;
    }

    .preview-project img {
        width: 100%;
        height: auto;
    }
}