.carousel-container {
    position: relative;
}

.carousel .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 3em;
    color: white;
    cursor: pointer;
}

.carousel .arrow.previous {
    left: 10px;
}

.carousel .arrow.next {
    right: 10px;
}

.carousel-container img {
    max-width: 1000px;
}
